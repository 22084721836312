import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Question} from "./Question";

export function QuestionModal(props: { open: boolean, setOpen: Function }) {
  return <Modal isOpen={props.open} backdrop={true}>
    <ModalHeader toggle={() => props.setOpen(!props.open)}>Fragen und so</ModalHeader>
    <ModalBody>
      <Question
        title={"Was geht hier ab?"}
        body={<>
          <p>Es spricht sich allmählich herum: KoRo hat richtig gutes Zeug. In richtig großen Packungen. Das hat seine Vorzüge, ist aber uncool für alle, die nur mal probieren wollen oder denen auch ein kleinerer Vorrat reichen würde.</p>
          <p>Aber was wäre, wenn sich mehrere dieser Leute zusammenfänden, um sich die Großpackungen zu teilen? Genau das ist es, was hier abgeht: Sag, was du haben möchtest, andere sagen das auch und wenn es für eine Packung reicht, wird sie bestellt und aufgeteilt.</p>
        </>}
      />
      <Question
        title={"Worin werden die Sachen abgefüllt?"}
        body={<>
          <p>Am besten gibst du mir Vorratsdosen oder -gläser, die ich dann für dich befülle. Ansonsten kannst du neue Vorratsgläser mitbestellen, Altglas recyclen oder die Originalverpackung der Artikel bekommen.</p>
          <p>Ich melde mich rechtzeitig bei dir, um die Details zu verabreden.</p>
        </>
        }
      />
      <Question
        title={"Wie läuft das mit dem Transport?"}
        body={<>
          <p>Ich bin gerne mit dem Rad unterwegs und freue mich auf Liefertouren in und um Kassel. Größere Entfernungen sind nach Absprache auch machbar – oder Paketversand.</p>
        </>
        }
      />
      <Question
        title={"Wie bezahle ich?"}
        body={<>
          <p>Am besten per PayPal oder Überweisung. Bar geht zur Not auch.</p>
        </>
        }
      />
      <Question
        title={"Was soll die Option mindestens/ungefähr/höchstens?"}
        body={<>
          <p>Nur die wenigsten der hier angebotenen Artikel habe ich auf Lager, die meisten werden erst bei Bedarf bestellt. Da wir nicht eineinhalb Packungen bestellen können, brauche ich von dir etwas Flexibilität:</p>
          <p>Wenn du "höchstens Menge x" bestellst, bedeutet das, du wärst auch mit der Hälfte zufrieden. Mit "Mindestens Menge X" erklärst du dich bereit, dich auch mit der doppelten Menge zu beteiligen. "Ungefähr" ist ein Mittelding aus den beiden anderen Optionen.</p>
          <p>Diese Intervalle machen es möglich, dass es immer eine korrekte Anzahl an zu bestellenden Packungen gibt. Welche Menge du letztlich bekommst, hängt dann davon ab, was die anderen bestellen. Selbstverständlich zahlst du genau für die Menge, die du bekommst.</p>
        </>
        }
      />
      <Question
        title={"Was passiert, wenn nicht genug Interesse für eine ganze Packung besteht?"}
        body={<>
          <p>Deine Bestellung bleibt für vier Wochen aktiv. Falls sich in dieser Zeit noch andere für das Produkt interessieren, ist dir dein Anteil an der Packung sicher. Anderenfalls verfällt deine Bestellung.</p>
        </>
        }
      />
      <Question
        title={"Wie viel Zeit habe ich?"}
        body={<>
          <p>Nach der Bestellung ist vor der Bestellung. Ich bestelle regelmäßig etwa einmal pro Monat bei KoRo. Im schlimmsten Fall musst du also ein paar Wochen Geduld haben.</p>
        </>
        }
      />
      <Question
        title={"Darf ich den Link weitergeben?"}
        body={<>
          <p>Ja! Je mehr Leute mitmachen, umso besser können wir die Sachen aufteilen. Gib gerne auch meine Handynummer an die Neuzugänge weiter, damit wir das organisatorische Drumherum regeln können.</p>
        </>
        }
      />
      <Question
        title={"Wo kann ich weitere Fragen stellen?"}
        body={<>
          <p>Du erreichst mich per WhatsApp und Telegram. Falls wir uns noch nicht persönlich kennen, lass dir gerne meine Nummer geben und sag mal Hallo!</p>
        </>
        }
      />
    </ModalBody>
  </Modal>;
}