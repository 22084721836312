import {Card, CardBody, CardTitle, Collapse} from "reactstrap";
import React, {useState} from "react";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function Question(props: { title: string, body: any }) {
  const [open, setOpen] = useState<boolean>(false);
  const toggle = () => setOpen(!open);
  return <Card>
    <CardBody onClick={toggle} style={{padding: "0.8rem", cursor: "pointer"}}>
      <CardTitle tag={"h6"} className={"mb-0"}>
        <div className={"d-flex justify-content-between"}>
          {props.title}
          <FontAwesomeIcon icon={faCaretDown}/>
        </div>
      </CardTitle>
    </CardBody>
    <Collapse isOpen={open}>
      <CardBody style={{padding: "0.8rem"}}>
        {props.body}
      </CardBody>
    </Collapse>
  </Card>;
}