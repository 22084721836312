import {Order} from "./types/Order";
import {Button} from "reactstrap";
import {faRedo, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getContentUnit} from "./types/ContentUnit";
import React from "react";

export function OrderTableRow(props: { order: Order, columns: any, refreshOrder: Function }) {

  return <tr>

    {
      props.columns.orderId.visible &&
      <td style={{textAlign: "right", verticalAlign: "middle"}}>{props.order.orderId}</td>
    }

    {
      props.columns.user.visible &&
      <td style={{verticalAlign: "middle"}}>{props.order.userName || props.order.userId}</td>
    }

    {
      props.columns.deliverTime.visible &&
      <td style={{verticalAlign: "middle", textAlign: "right"}}>
        {new Date(props.order.deliverTime!).toLocaleDateString()}
      </td>
    }

    {
      props.columns.amount.visible &&
      <td style={{verticalAlign: "middle", textAlign: "right"}}>
        {
          props.order.containerAmount
            ? parseFloat(props.order.containerAmount).toFixed(0)
            : props.order.proposedAmount
        }&nbsp;{getContentUnit(props.order.contentUnit)}
      </td>
    }

    {
      props.columns.product.visible &&
      <td style={{verticalAlign: "middle"}}>{props.order.productName}</td>
    }

    {
      props.columns.minPrefMaxAmount.visible &&
      <>
          <td style={{
            verticalAlign: "middle",
            textAlign: "right",
            fontSize: "70%",
            opacity: props.order.minAmount == props.order.preferredAmount ? 0.3 : 1
          }}>
              <div>{props.order.minAmount}&nbsp;{getContentUnit(props.order.contentUnit)}</div>
              <div>
                {
                  props.order.minPrice.toFixed(2).replace(".", ",")
                }&nbsp;€
              </div>
          </td>
          <td style={{
            verticalAlign: "middle",
            textAlign: "right",
            fontSize: "70%"
          }}>
              <div>{props.order.preferredAmount}&nbsp;{getContentUnit(props.order.contentUnit)}</div>
              <div>
                {
                  props.order.preferredPrice.toFixed(2).replace(".", ",")
                }&nbsp;€
              </div>
          </td>
          <td style={{
            verticalAlign: "middle",
            textAlign: "right",
            fontSize: "70%",
            opacity: props.order.maxAmount == props.order.preferredAmount ? 0.3 : 1
          }}>
              <div>{props.order.maxAmount}&nbsp;{getContentUnit(props.order.contentUnit)}</div>
              <div>
                {
                  props.order.maxPrice.toFixed(2).replace(".", ",")
                }&nbsp;€
              </div>
          </td>
      </>
    }

    {
      props.columns.whatsHappening.visible &&
      <td style={{verticalAlign: "middle"}}>
        {
          props.order.containerTime
            ? "Bereit zur Lieferung"
            : props.order.stockTime
            ? "Bereit zum Abfüllen"
            : props.order.stockOrderTime
              ? "Für dich bestellt"
              : "Wird bald bestellt"
        }
      </td>
    }

    {
      props.columns.price.visible &&
      <td style={{textAlign: "right", verticalAlign: "middle"}}>
        {
          <>
            {
              parseFloat((props.order.price ? props.order.price : props.order.proposedPrice!) + "").toFixed(2).replace(".", ",")
            }&nbsp;€
          </>
        }
      </td>
    }

    {
      props.columns.activeTime.visible &&
      <td style={{textAlign: "right", verticalAlign: "middle"}}>
        {
          <>
            {((Date.parse(props.order.activeUntil) - new Date().getTime()) / (1000 * 60 * 60 * 24)).toFixed(0)}
            &nbsp;Tag(e)
          </>
        }
      </td>
    }

    {
      props.columns.reactivatable.visible &&
      <td style={{verticalAlign: "middle"}}>
          <Button outline={true} size={"sm"}>
              <FontAwesomeIcon icon={faRedo} onClick={() => props.refreshOrder(props.order.orderId)}/>
          </Button>
      </td>
    }

    {
      props.columns.deletable.visible &&
      <td style={{verticalAlign: "middle"}}>
          <Button outline={true} size={"sm"}>
              <FontAwesomeIcon icon={faTrash}/>
          </Button>
      </td>
    }

  </tr>;
}