import {NavItem, NavLink} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classnames from "classnames";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

export function OrdersTab(props: { tabIcon: IconDefinition, tabId: string, tabTitle: string, activeTab: string, toggle: Function }) {
  return <NavItem>
    <NavLink
      className={classnames({active: props.activeTab === props.tabId})}
      style={{cursor: "pointer"}}
      onClick={() => props.toggle(props.tabId)}
    >
      <FontAwesomeIcon icon={props.tabIcon} className={"mr-2"}/> {props.tabTitle}
    </NavLink>
  </NavItem>;
}