import {Order} from "./types/Order";
import {Table} from "reactstrap";
import {OrderTableRow} from "./OrderTableRow";
import React from "react";
import {User} from "./types/User";

export function OrderTable(props: { orders: Order[], orderState: string, authedUser: User, refreshOrder: Function }) {

  const priceSum = {
    min: props.orders.map(order => order.minPrice).reduce((a, b) => a + b, 0),
    preferred: props.orders.map(order => order.preferredPrice).reduce((a, b) => a + b, 0),
    max: props.orders.map(order => order.maxPrice).reduce((a, b) => a + b, 0),
    proposed: props.orders.map(order => order.proposedPrice ? parseFloat(order.proposedPrice + "") : 0).reduce((a, b) => a + b, 0),
  };

  const god = props.authedUser && props.authedUser.userId == 1;

  const columns = {
    orderId: {
      visible: god,
      head: <th>#</th>,
      foot: <td/>,
    },
    user: {
      visible: god,
      head: <th>Wer</th>,
      foot: <td/>,
    },
    deliverTime: {
      visible: props.orderState === "delivered",
      head: <th style={{textAlign: "right"}}>Lieferdatum</th>,
      foot: <td/>,
    },
    amount: {
      visible: props.orderState === "processing" || props.orderState === "delivered",
      head: <th style={{textAlign: "right"}}>Menge</th>,
      foot: <td/>,
    },
    product: {
      visible: true,
      head: <th>Produkt</th>,
      foot: <td/>,
    },
    minPrefMaxAmount: {
      visible: props.orderState === "active" || props.orderState === "inactive",
      head: <th colSpan={3} style={{textAlign: "right"}}>Menge <span style={{fontSize: "70%"}}>(min/gewünscht/max)</span></th>,
      foot: <>
        <td style={{textAlign: "right"}}>{priceSum.min.toFixed(2).replace(".", ",")}&nbsp;€</td>
        <td style={{textAlign: "right"}}>{priceSum.preferred.toFixed(2).replace(".", ",")}&nbsp;€</td>
        <td style={{textAlign: "right"}}>{priceSum.max.toFixed(2).replace(".", ",")}&nbsp;€</td>
      </>,
    },
    whatsHappening: {
      visible: props.orderState === "processing",
      head: <th>Wie steht's?</th>,
      foot: <td/>,
    },
    price: {
      visible: props.orderState === "processing" || props.orderState === "delivered",
      head: <th style={{textAlign: "right"}}>Preis</th>,
      foot: props.orderState === "processing"
        ? <td style={{textAlign: "right"}}>{priceSum.proposed.toFixed(2).replace(".", ",")}&nbsp;€</td>
        : <td/>,
    },
    activeTime: {
      visible: props.orderState === "active",
      head: <th style={{textAlign: "right"}}>Aktiv</th>,
      foot: <td/>,
    },
    reactivatable: {
      visible: props.orderState === "active" || props.orderState === "inactive",
      head: <th/>,
      foot: <td/>,
    },
    deletable: {
      visible: false && props.orderState === "active",
      head: <th/>,
      foot: <td/>,
    },
  };

  if (!props.orders.length) {
    return <>(Hier gibt es nichts zu sehen)</>;
  }

  return <Table size={"sm"} responsive>
    <thead>
    <tr>
      {
        Object
          .values(columns)
          .filter(column => column.visible)
          .map(column => column.head)
      }
    </tr>
    </thead>
    <tbody>
    {
      props.orders.map(order => <OrderTableRow order={order} columns={columns} refreshOrder={props.refreshOrder}/>)
    }
    </tbody>
    <tfoot style={{fontWeight: "bold"}}>
    <tr>
      {
        Object
          .values(columns)
          .filter(column => column.visible)
          .map(column => column.foot)
      }
    </tr>
    </tfoot>
  </Table>;
}