import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {createRef, useState} from "react";
import {Alert, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Nav, Navbar, NavbarBrand, NavItem, NavLink, Spinner, UncontrolledDropdown} from "reactstrap";
import {faFilter, faInfo, faSearch, faShoppingCart, faSignOutAlt, faSyncAlt, faUser, faUserCheck} from "@fortawesome/free-solid-svg-icons";
import {Filter} from "./types/Filter";
import {User} from "./types/User";

export function NavBar(props: {
  authedUser: User | undefined,
  categories: string[],
  filter: Filter,
  toggleFilterCategory: Function,
  setFilterSearch: Function,
  toggleFilterCart: Function,
  changingFilter: boolean,
  searching: boolean,
  collectingCart: boolean,
  logout: Function,
  requestLoginToken: Function,
  userTabOpen: boolean,
  setUserTabOpen: Function,
  setFragenModalOpen: Function
}) {
  const [searchOpen, setSearchOpen] = useState(false);
  const [loginTokenRequested, setLoginTokenRequested] = useState<boolean>(false);
  const toggleSearchOpen = () => setSearchOpen(!searchOpen);
  const toggleUserTabOpen = () => props.setUserTabOpen(!props.userTabOpen);

  const search = createRef<HTMLInputElement>();
  const doSearch = () => props.setFilterSearch(search.current!.value);

  const email = createRef<HTMLInputElement>();

  return <Navbar color="dark" dark fixed={"top"}>
    <NavbarBrand></NavbarBrand>
    <Nav/>
    <Nav>
      <NavItem style={{cursor: "pointer"}}>
        <NavLink onClick={() => props.setFragenModalOpen(true)}>
          <FontAwesomeIcon icon={faInfo}/>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="/">
          <FontAwesomeIcon icon={faSyncAlt}/>
        </NavLink>
      </NavItem>
      <UncontrolledDropdown nav inNavbar onClick={() => props.toggleFilterCart()}>
        <DropdownToggle nav>
          {props.collectingCart
            ? <Spinner animation={"border"} size={"sm"}/>
            : <FontAwesomeIcon icon={faShoppingCart} className={props.filter.cart ? "text-info" : ""}/>
          }
        </DropdownToggle>
      </UncontrolledDropdown>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav>
          {props.changingFilter
            ? <Spinner animation={"border"} size={"sm"}/>
            : <FontAwesomeIcon icon={faFilter} className={props.filter.category && "text-info"}/>
          }
        </DropdownToggle>
        <DropdownMenu flip={false} style={{maxHeight: "90vh", overflow: "auto", left: "50%", transform: "translateX(-50%)"}}>
          <DropdownItem onClick={() => props.toggleFilterCategory(undefined)}>
            Alle Kategorien
          </DropdownItem>
          <DropdownItem divider/>
          {
            props.categories.map(category =>
              <DropdownItem onClick={() => props.toggleFilterCategory(category)} key={category}>
                {category}
              </DropdownItem>
            )
          }
        </DropdownMenu>
      </UncontrolledDropdown>
      <Dropdown nav inNavbar isOpen={searchOpen} toggle={toggleSearchOpen}>
        <DropdownToggle nav onClick={() => search.current ? search.current.focus() : console.log("meh")}>
          {props.searching
            ? <Spinner animation={"border"} size={"sm"}/>
            : <FontAwesomeIcon icon={faSearch} className={props.filter.search && "text-info"}/>
          }
        </DropdownToggle>
        <DropdownMenu right className={"p-0 border-0"}>
          <div className={"p-2"}>
            <Form onSubmit={event => {
              doSearch();
              setSearchOpen(false);
              event.preventDefault();
            }}>
              <Input
                type={"search"}
                innerRef={search}
                placeholder={"Suche..."}
                onBlur={doSearch}
                style={{width: "calc(100vw - 8rem)", maxWidth: "80vw"}}
              />
            </Form>
          </div>
        </DropdownMenu>
      </Dropdown>
      <Dropdown nav inNavbar isOpen={props.userTabOpen} toggle={toggleUserTabOpen}>
        <DropdownToggle nav>
          <FontAwesomeIcon icon={props.authedUser ? faUserCheck : faUser}/>
        </DropdownToggle>
        <DropdownMenu right flip={false} style={{maxHeight: "90vh", overflow: "auto"}}>
          <div className={"px-3 py-2"} style={{width: "18rem", maxWidth: "75vw"}}>
            {
              props.authedUser &&
              <>
                  <p>Hi {props.authedUser.name}!</p>
                  <p><Button onClick={() => props.logout()}><FontAwesomeIcon icon={faSignOutAlt}/> Logout</Button></p>
              </>
            }
            {
              !props.authedUser && loginTokenRequested &&
              <Alert color={"success"} isOpen={loginTokenRequested} toggle={() => setLoginTokenRequested(false)}>
                  Sie haben Post!
              </Alert>
            }
            {
              !props.authedUser && !loginTokenRequested &&
              <Form onSubmit={e => {
                props.requestLoginToken({email: email.current?.value}).then(() => setLoginTokenRequested(true));
                e.preventDefault()
              }}>
                  <h4>Anmeldung</h4>
                  <FormGroup>
                      <Input type={"email"} placeholder={"Deine E-Mail-Adresse"} innerRef={email} style={{width: "100%"}}/>
                  </FormGroup>
                  <FormGroup>
                      <Button
                          style={{width: "100%"}}
                      >
                          Anmeldelink senden
                      </Button>
                  </FormGroup>
              </Form>
            }
          </div>
        </DropdownMenu>
      </Dropdown>
    </Nav>
  </Navbar>;
}