export function getContentUnit(key?: string) {
  if (key === "GRAM") {
    return "g";
  }

  if (key === "MILLILITER") {
    return "ml";
  }

  if (key === "PIECE") {
    return "Stück";
  }

  if (key === "PACKAGE") {
    return "Paket";
  }

  // T-Shirts
  if (key === null) {
    return "Stück";
  }

  throw Error("Unknown content unit key " + key);
}
