import {useRef} from "react";

export function RandomOrderLine(props: { type: string }) {
  let nice = [
    'Klingt gut',
    'Hab ich Bock drauf',
    'Coole Sache',
    'Mega',
    'Geil',
    'Geiles Zeug',
    'Deal',
    'Nice',
    'Überzeugt',
    'Hammer',
  ];

  let give = [
    'Gib mir',
    'Ich nehme',
    'Ich hätte gerne',
    'Ich bestelle',
    'Ich möchte',
    'Ich will',
    'Ich wünsche mir',
    'Ich begehre',
    'Ich verlange',
    'Ich gönne mir',
  ]

  const niceLine = useRef<string>(nice[Math.floor(Math.random() * nice.length)]);
  const giveLine = useRef<string>(give[Math.floor(Math.random() * give.length)]);

  if (props.type === "nice") {
    return <>{niceLine.current}</>;
  } else if (props.type === "give") {
    return <>{giveLine.current}</>;
  } else {
    throw new Error("what?");
  }
}