import React, {useEffect, useState} from 'react';
import ky from "ky";
import {Container, Pagination, PaginationItem, PaginationLink, Spinner} from 'reactstrap';
import {NavBar} from "./NavBar";
import {ProductView} from "./ProductView";
import {Product} from "./types/Product";
import {Filter} from "./types/Filter";
import {OrderProgress} from "./types/OrderProgress";
import {User} from "./types/User";
import {QuestionModal} from "./QuestionModal";
import {Orders} from "./Orders";
import {Order} from "./types/Order";

function App() {
  const [authedUser, setAuthedUser] = useState<User | undefined>(undefined);
  const [userTabOpen, setUserTabOpen] = useState<boolean>(false);
  const [fragenModalOpen, setFragenModalOpen] = useState<boolean>(false);

  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [orderProgress, setOrderProgress] = useState<OrderProgress[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [howManyPackages, setHowManyPackages] = useState<any>({});

  const [filter, setFilter] = useState<Filter>({});
  const [changingFilter, setChangingFilter] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [collectingCart, setCollectingCart] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const toggleFilterCategory = (category: string) => {
    setChangingFilter(true);
    window.setTimeout(() => {
      setChangingFilter(false);
      setFilter({...filter, "category": filter.category !== category ? category : undefined})
    }, 10);
  };
  const setFilterSearch = (search: string) => {
    setSearching(true);
    window.setTimeout(() => {
      setSearching(false);
      setFilter({...filter, "search": search})
    }, 10);
  };
  const toggleFilterCart = () => {
    setCollectingCart(true);
    window.setTimeout(() => {
      setCollectingCart(false);
      setFilter({...filter, cart: !filter.cart})
    }, 10);
  };

  const productsPerPage = 75;

  useEffect(() => {
    let loginToken = new URLSearchParams(window.location.search).get('loginToken');
    if (loginToken) {
      login({loginToken});
      window.history.replaceState({}, document.title, `/`);
    } else {
      checkAuth();
    }
  }, []);

  let apiUrl = window.location.protocol + "//" + window.location.hostname;
  let fetchProducts = () => ky.get(apiUrl + "/product/").json<Product[]>().then(r => setProducts(r));
  let fetchCategories = () => ky.get(apiUrl + "/category/").json<string[]>().then(r => setCategories(r));
  let fetchProgress = () => ky.get(apiUrl + "/order/progress/").json<OrderProgress[]>().then(r => setOrderProgress(r));
  let fetchOrders = () => ky.get(apiUrl + "/order/", {credentials: "include"}).json<Order[]>().then(r => setOrders(r));
  let fetchHowManyPackages = () => ky.get(apiUrl + "/order/howManyPackages/", {credentials: "include"}).json<any[]>().then(r => setHowManyPackages(r));

  let requestLoginToken = (params: { email: string }) => ky.post(apiUrl + "/auth/loginToken/", {json: params});

  let refreshOrder = (orderId: number) =>
    ky.post(apiUrl + "/order/refresh/", {json: {orderId}, credentials: "include"})
      .then(() => fetchOrders());

  let login = (params: { email?: string, password?: string, loginToken?: string }) =>
    ky.post(apiUrl + "/auth/", {json: params, credentials: "include"})
      .json<User>()
      .then(r => setAuthedUser(r))
      .catch(() => window.alert('Dein Anmeldelink ist abgelaufen. :( Bitte fordere einen neuen an!'));

  let logout = () => ky.get(apiUrl + "/auth/logout", {credentials: "include"}).then(checkAuth);

  let checkAuth = () => ky.get(apiUrl + "/auth/", {credentials: "include"}).json<User>().then(r => setAuthedUser(r));

  let updateOrders = () => {
    fetchOrders();
    fetchProgress();
  };

  useEffect(() => {
    fetchProducts();
    fetchCategories();
    fetchProgress();
    fetchOrders();
    fetchHowManyPackages();
  }, []);

  useEffect(() => {
    fetchOrders();
    fetchHowManyPackages();
  }, [authedUser])

  useEffect(() => setCurrentPage(0), [filter]);

  let filteredProducts = products
    .filter(product => filter.category === undefined || (product.categories && product.categories.includes(filter.category)))
    .filter(product => !filter.search || product.name.toLowerCase().includes(filter.search.toLowerCase()))
    .filter(product => !filter.cart || (orders.find(order => authedUser?.userId === order.userId && order.productId === product.productId && order.state === "active")));

  let pages: number[] = [];
  for (let page = 0; pages.length * productsPerPage < filteredProducts.length; page++) {
    pages.push(page);
  }
  const goToPage = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  }

  return (
    <>
      <NavBar
        authedUser={authedUser}
        userTabOpen={userTabOpen}
        setUserTabOpen={setUserTabOpen}
        categories={categories}
        filter={filter}
        toggleFilterCategory={toggleFilterCategory}
        setFilterSearch={setFilterSearch}
        toggleFilterCart={toggleFilterCart}
        changingFilter={changingFilter}
        searching={searching}
        collectingCart={collectingCart}
        logout={logout}
        requestLoginToken={requestLoginToken}
        setFragenModalOpen={setFragenModalOpen}
      />
      <Container>
        {
          products.length === 0 &&
          <div className={"text-center"}>
              <Spinner
                  animation={"border"}
                  color={"secondary"}
                  style={{width: "10rem", height: "10rem", margin: "2rem", animationDuration: "2s", borderWidth: "1.5em"}}
              />
              <h1>Hey!</h1>
              <h2>Geht gleich los.</h2>
              <h3>Hole noch Snacks...</h3>
          </div>
        }
        {
          filter.cart &&
          <Orders
              orders={orders}
              authedUser={authedUser!}
              refreshOrder={refreshOrder}
              howManyPackages={howManyPackages}
          />
        }
        {
          filteredProducts
            .slice(currentPage * productsPerPage, (currentPage + 1) * productsPerPage)
            .map(product =>
              <ProductView
                authedUser={authedUser}
                apiUrl={apiUrl}
                key={product.productId}
                product={product}
                order={orders.find(order => order.productId === product.productId && order.state === "active" && order.userId === authedUser?.userId)}
                orderProgress={orderProgress.find(orderProgress => orderProgress.productId === product.productId)}
                updateOrders={updateOrders}
                filter={filter}
                toggleFilterCategory={toggleFilterCategory}
                setUserTabOpen={setUserTabOpen}
              />
            )
        }
        {
          filteredProducts.length > productsPerPage &&
          <div className={"text-center"} style={{maxWidth: "100%", overflow: "auto"}}>
              <div style={{display: "inline-block"}}>
                  <Pagination>
                      <PaginationItem disabled={currentPage === 0} key={"first"}>
                          <PaginationLink first onClick={() => goToPage(0)}/>
                      </PaginationItem>
                      <PaginationItem disabled={currentPage === 0} key={"prev"}>
                          <PaginationLink previous onClick={() => goToPage(currentPage - 1)}/>
                      </PaginationItem>
                    {
                      pages.map(page =>
                        <PaginationItem active={currentPage === page} key={page}>
                          <PaginationLink onClick={() => goToPage(page)}>
                            {page + 1}
                          </PaginationLink>
                        </PaginationItem>
                      )
                    }
                      <PaginationItem disabled={currentPage === pages.length - 1} key={"next"}>
                          <PaginationLink next onClick={() => goToPage(currentPage + 1)}/>
                      </PaginationItem>
                      <PaginationItem disabled={currentPage === pages.length - 1}>
                          <PaginationLink last onClick={() => goToPage(pages.length - 1)} key={"last"}/>
                      </PaginationItem>
                  </Pagination>
              </div>
          </div>
        }
      </Container>
      <QuestionModal
        open={fragenModalOpen}
        setOpen={setFragenModalOpen}
      />
    </>
  );
}

export default App;
