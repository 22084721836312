import {Card, CardBody, CardText, Nav, TabContent, Table, TabPane} from "reactstrap";
import {useState} from "react";
import {OrdersTab} from "./OrdersTab";
import {faCheck, faHourglassEnd, faShoppingCart, faTruck} from "@fortawesome/free-solid-svg-icons";
import {Order} from "./types/Order";
import {OrderTable} from "./OrderTable";
import {User} from "./types/User";
import {getContentUnit} from "./types/ContentUnit";

export function Orders(props: { orders: Order[], authedUser: User, refreshOrder: Function, howManyPackages: any }) {
  const [activeTab, setActiveTab] = useState<string>("active");

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const tabs = [
    {title: "Offen", state: "active", icon: faShoppingCart, text: "Bei diesen Bestellungen ist alles möglich. Wenn ausreichend Interesse an einem Produkt besteht, wird es bei der nächsten Großbestellung für dich mitbestellt. Bis dahin kannst du deine Bestellung beliebig oft anpassen oder widerrufen. Falls nicht genug Interesse für eine Packung zusammenkommt, verfällt deine Bestellung nach vier Wochen."},
    {title: "In Bearbeitung", state: "processing", icon: faTruck, text: "It's happening. Diese Bestellungen sind unterwegs - irgendwo zwischen der Großbestellung und der Lieferung an dich. Die Mengen können sich noch geringfügig ändern."},
    {title: "Inaktiv", state: "inactive", icon: faHourglassEnd, text: "Für diese Bestellungen gab es nicht genug Interesse. Falls du immer noch Lust hast, reaktiviere die Bestellungen mit dem Button."},
    {title: "Geliefert", state: "delivered", icon: faCheck, text: "Dies sind deine bereits erfolgten Lieferungen."},
  ];

  return <>
    <Nav tabs>
      {
        tabs.map(tab =>
          <OrdersTab
            tabIcon={tab.icon}
            tabTitle={tab.title}
            key={tab.state}
            tabId={tab.state}
            activeTab={activeTab}
            toggle={toggle}
          />
        )
      }
    </Nav>
    <Card className={"mb-3"}>
      <CardBody>
        <CardText>
          <TabContent activeTab={activeTab}>
            {tabs.map(tab =>
              <TabPane key={tab.state} tabId={tab.state}>
                <div className={"mb-4"}>{tab.text}</div>
                <OrderTable
                  orders={props.orders.filter(order => order.state === tab.state)}
                  orderState={tab.state}
                  authedUser={props.authedUser}
                  refreshOrder={props.refreshOrder}
                />
                {
                  tab.state === "active" && props.authedUser && props.authedUser.userId == 1 &&
                  <>
                      <h2>Zu bestellendes</h2>
                      <Table>
                        {Array.isArray(props.howManyPackages.byProduct) && props.howManyPackages.byProduct.map((product: any) =>
                          <tr>
                            <td>{product.productName}</td>
                          </tr>
                        )}
                      </Table>
                      <h2>Bestellende</h2>
                    {Array.isArray(props.howManyPackages.byUser) && props.howManyPackages.byUser.map((user: any) =>
                      <>
                        <h4>{user.userId}</h4>
                        <Table size={"sm"}>
                          {user.orders.map((order: any) =>
                            <tr style={{opacity: order.availability === "InStock" ? 1 : 0.3}}>
                              <td style={{textAlign: "right"}}>{order.realAmount}&nbsp;{getContentUnit(order.contentUnit)}</td>
                              <td title={order.minAmount + "-" + order.maxAmount + " (" + order.preferredAmount + ")"}>{order.name}</td>
                              <td style={{textAlign: "right"}}>{parseFloat(order.price).toFixed(2).replace(".", ",")}&nbsp;€</td>
                            </tr>
                          )}
                          <tr>
                            <td/>
                            <td/>
                            <td style={{textAlign: "right", fontWeight: "bold"}}>{parseFloat(user.sum).toFixed(2).replace(".", ",")}&nbsp;€</td>
                          </tr>
                        </Table>
                      </>
                    )}
                  </>
                }
              </TabPane>
            )}
          </TabContent>
        </CardText>
      </CardBody>
    </Card>
  </>;
}