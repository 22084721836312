import {Alert, Button, Card, CardBody, CardTitle, Col, Collapse, Form, Input, Progress, Row, Table} from "reactstrap";
import React, {useEffect, useState} from "react";
import {Product} from "./types/Product";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faChevronUp, faExternalLinkAlt, faQuestionCircle, faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import {OrderOption} from "./types/OrderOption";
import {RandomOrderLine} from "./RandomOrderLine";
import {getContentUnit} from "./types/ContentUnit";
import {Filter} from "./types/Filter";
import ky from "ky";
import {OrderProgress} from "./types/OrderProgress";
import {User} from "./types/User";
import {UserOrderLocal} from "./types/UserOrderLocal";
import {Order} from "./types/Order";

export function ProductView(props: {
  authedUser: User | undefined,
  apiUrl: string,
  product: Product,
  order?: Order,
  orderProgress?: OrderProgress,
  updateOrders: Function,
  filter: Filter,
  toggleFilterCategory: Function,
  setUserTabOpen: Function
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [userOrderLocal, setUserOrderLocal] = useState<UserOrderLocal>(props.order || {});
  const [userOrderIntervalLogic, setUserOrderIntervalLogic] = useState<string>("max");
  const [individualAmount, setIndividualAmount] = useState<number | undefined>(undefined);

  const updateOrder = (orderUpdates?: UserOrderLocal) => {
    let updatedOrder = {
      ...userOrderLocal,
      ...orderUpdates
    };

    if (updatedOrder.preferredAmount === undefined) {
      return;
    }

    // apply interval logic to min/max amounts
    if (orderUpdates?.preferredAmount) {
      if (userOrderIntervalLogic === "min") {
        updatedOrder.minAmount = Math.floor(updatedOrder.preferredAmount);
        updatedOrder.maxAmount = Math.ceil(updatedOrder.preferredAmount * props.product.orderAmountInterval);
      } else if (userOrderIntervalLogic === "circa") {
        let minByInterval = Math.floor(updatedOrder.preferredAmount / Math.sqrt(props.product.orderAmountInterval)); // TODO
        let maxByInterval = Math.ceil(updatedOrder.preferredAmount * Math.sqrt(props.product.orderAmountInterval));
        updatedOrder.minAmount = minByInterval;
        updatedOrder.maxAmount = maxByInterval;
      } else if (userOrderIntervalLogic === "max") {
        updatedOrder.minAmount = Math.floor(updatedOrder.preferredAmount / props.product.orderAmountInterval);
        updatedOrder.maxAmount = Math.ceil(updatedOrder.preferredAmount);
      }
    }

    setUserOrderLocal(updatedOrder);
    return updatedOrder;
  }

  useEffect(() => {
    updateOrder(props.order)
  }, [props.order])

  const postOrder = (orderUpdates?: UserOrderLocal) => {
    let updatedOrder = updateOrder(orderUpdates);
    console.log("POSTING", updatedOrder)
    ky.post(props.apiUrl + "/order/", {
      json: {
        ...updatedOrder,
        "productId": props.product.productId
      },
      credentials: "include"
    }).json().then(r => props.updateOrders()).catch(r => console.log("caught", r));
  };

  let minContentAmount = props.product.minContentAmount ? parseFloat(props.product.minContentAmount + '') : undefined;
  let allowSplitting = !(props.product.allowSplitting == false);

  let progress: number;
  if (!allowSplitting) {
    progress = 0;
  } else if (props.product.orderAmountInterval == 1) {
    progress = 1;
  } else {
    progress = props.orderProgress && minContentAmount
      ? props.orderProgress.orderedAmount / minContentAmount
      : 0;
  }
  let color = progress < 1 ? "warning" : "success";

  let contentUnit = props.product.articles && props.product.articles.length > 0 && getContentUnit(props.product.articles[0].contentUnit);

  let orderOptions: OrderOption[] = [];
  if (minContentAmount) {
    let serving = props.product.serving ? props.product.serving : Math.ceil((minContentAmount / 20) / 5) * 5;
    let vorrat = Math.ceil((minContentAmount / 4) / 5) * 5;
    if (serving < minContentAmount && allowSplitting) {
      orderOptions.push(new OrderOption(serving, "eine Probierportion"));
    }
    if (vorrat > serving && allowSplitting) {
      orderOptions.push(new OrderOption(vorrat, "einen Vorrat"));
    }
    orderOptions.push(new OrderOption(minContentAmount, "eine ganze Packung"));
  }

  return <Card className={"mb-2"}>
    <Progress value={progress * 100} color={"info"} style={{height: "0.15rem"}}/>
    <CardBody onClick={() => setOpen(!open)} style={{cursor: "pointer"}}>
      <CardTitle tag={"strong"}>
        <div className={"d-flex justify-content-between"}>
          {props.product.name}
          <div style={{whiteSpace: "nowrap"}}>
            {
              props.order &&
              <div style={{display: "inline-block", fontSize: "70%", color: "#999", lineHeight: 1}}>
                  <div className={"text-right ml-2"} style={{display: "inline-block"}}>
                      <div></div>
                  </div>
                  <div className={"text-right ml-2"} style={{display: "inline-block"}}>
                      <div>{props.order.preferredAmount} {contentUnit}</div>
                      <div>
                        {
                          (props.order.preferredAmount! / (minContentAmount || 1) * props.product.articles[0].price).toFixed(2).replace(".", ",")
                        } €
                      </div>
                  </div>
              </div>
            }
            {
              props.order &&
              <div style={{display: "inline-block"}}><FontAwesomeIcon icon={faShoppingCart} className={"ml-2 text-" + color}/></div>
            }
            {
              open &&
              <FontAwesomeIcon icon={faChevronUp} className={"ml-2"}/>
            }
          </div>
        </div>
      </CardTitle>
    </CardBody>
    <Collapse isOpen={open}>
      <CardBody style={{paddingTop: 0}}>
        <Row>
          <Col lg={5} md={5} sm={12}>
            {
              props.product.categories &&
              <div>
                {
                  props.product.categories
                    .map(category =>
                      <Button
                        color={"secondary"}
                        outline={props.filter.category !== category}
                        size={"sm"}
                        style={{marginRight: "0.5rem", marginBottom: "0.5rem"}}
                        key={category}
                        onClick={() => props.toggleFilterCategory(category)}
                      >
                        #{category}
                      </Button>
                    )
                }
              </div>
            }
            <div style={{paddingTop: "0.9rem"}}>
              {props.product.description}
            </div>
            {
              props.product.articles[0].url &&
              <div style={{paddingTop: "0.9rem"}}>
                  <a href={props.product.articles[0].url} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faExternalLinkAlt}/>
                      <span className={"ml-2"}>Mehr auf {new URL(props.product.articles[0].url).hostname}</span>
                  </a>
              </div>
            }
          </Col>
          <Col lg={7} md={7} sm={12}>
            <div className={"mt-4 mt-md-0"}>
              {
                !(props.order) &&
                <Form onSubmit={event => {
                  individualAmount && postOrder({preferredAmount: individualAmount});
                  event.preventDefault();
                }}>
                    <div><strong><RandomOrderLine type={"nice"}/>{props.authedUser ? "!" : "?"}</strong></div>
                  {
                    !props.authedUser &&
                    <Alert color={"dark"} className={"mt-3"}>
                        <p>Dir juckt es schon in den Fingern? Nur eine schnelle Anmeldung trennt dich von unbegrenztem Bestellvergnügen!</p>
                        <p><Button onClick={() => props.setUserTabOpen(true)}>Wo kann ich unterschreiben?</Button></p>
                    </Alert>
                  }
                    <span><RandomOrderLine type={"give"}/></span>
                  {
                    props.product.orderAmountInterval > 1 &&
                    <>
                      {' '}
                        <Input
                            type={"select"}
                            value={userOrderIntervalLogic}
                            onChange={event => setUserOrderIntervalLogic(event.target.value)}
                            style={{
                              display: "inline",
                              width: "auto",
                              background: "transparent",
                              padding: 0,
                              paddingRight: "1rem",
                              border: 0,
                              appearance: "none",
                              backgroundImage: "url(\"data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>\")",
                              backgroundRepeat: "no-repeat",
                              backgroundPositionX: "100%",
                              backgroundPositionY: "5px",
                            }}
                            disabled={!props.authedUser}
                        >
                          {[['min', 'mindestens'], ['circa', 'ungefähr'], ['max', 'höchstens']].map(option =>
                            <option value={option[0]} key={option[0]} style={{background: "#212529"}}>
                              {option[1]}
                            </option>
                          )}
                        </Input>
                        {/*<FontAwesomeIcon icon={faQuestionCircle} className={"ml-3"}/>*/}
                    </>
                  }

                    <Table borderless size={"sm"} style={{paddingTop: "1rem", width: "auto"}}>
                        <tbody>
                        {
                          orderOptions.map(option =>
                            <tr key={option.name}>
                              <td>
                                <Button
                                  color={(minContentAmount
                                    ? (+option.amount + (props.orderProgress ? props.orderProgress.orderedAmount : 0) >= minContentAmount || props.product.orderAmountInterval == 1
                                      ? "success"
                                      : "warning")
                                    : "primary")}
                                  onClick={() => postOrder({preferredAmount: option.amount})}
                                  style={{width: "11rem"}}
                                  disabled={!props.authedUser}
                                >
                                  {option.name}
                                </Button>
                              </td>
                              <td className={"text-right pl-2"} style={{fontSize: "70%"}}>
                                <div>{option.amount} {contentUnit}{
                                  props.product.gramsPerMilliliter && contentUnit !== "ml" &&
                                  <> ≈ {(option.amount / props.product.gramsPerMilliliter).toFixed(0)} ml</>
                                }</div>

                                <div>
                                  {minContentAmount &&
                                  (option.amount / minContentAmount * props.product.articles[0].price)
                                    .toFixed(2).replace(".", ",")} €
                                </div>
                              </td>
                            </tr>
                          )
                        }
                        <tr key={"individual"}>
                            <td>
                                <Input
                                    type={"number"}
                                    min={0}
                                    value={individualAmount || ''}
                                    placeholder={"Menge"}
                                    onChange={event => setIndividualAmount(parseFloat(event.target.value) || 0)}
                                    style={{display: "inline-block", width: "6rem", background: "transparent", appearance: "textfield"}}
                                    disabled={!props.authedUser}
                                /> {contentUnit}
                            </td>
                            <td className={"text-right pl-2"} style={{fontSize: "70%"}}>
                                <div>{individualAmount || 0} {contentUnit}{
                                  props.product.gramsPerMilliliter && contentUnit !== "ml" &&
                                  <> ≈ {((individualAmount || 0) / props.product.gramsPerMilliliter).toFixed(0)} ml</>
                                }</div>

                                <div>
                                  {
                                    minContentAmount && ((individualAmount || 0) / minContentAmount * props.product.articles[0].price)
                                      .toFixed(2).replace(".", ",")
                                  } €
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </Form>
              }
              {
                props.authedUser && props.order &&
                <>
                    <div>
                        <strong>Alles klar!</strong>
                    </div>
                    <div className={"mt-2"}>
                        Du bekommst{' '}
                        <strong style={{fontSize: "120%"}}>
                          {props.order.preferredAmount} {contentUnit}
                        </strong>
                      {
                        props.product.orderAmountInterval > 1 &&
                        <>
                          {' '}(oder irgendwas zwischen {props.order.minAmount} und {props.order.maxAmount} {contentUnit})
                        </>
                      }.
                    </div>
                    <div className={"mt-2"}>
                      {
                        progress < 1 && minContentAmount && allowSplitting &&
                        <>
                            Vielleicht aber auch gar nichts, denn zur ersten Packung fehlen noch {props.orderProgress ? minContentAmount - props.orderProgress!.orderedAmount : "ein paar"} {contentUnit}.
                        </>
                      }
                    </div>
                    <div className={"mt-3"}>
                      {
                        progress < 1 && minContentAmount && allowSplitting &&
                        <>
                            <div className={"mt-1"}>
                                <Button
                                    color={"success"}
                                    onClick={() => postOrder({maxAmount: minContentAmount! - props.orderProgress!.orderedAmount + userOrderLocal.maxAmount!})}
                                    style={{width: "16rem"}}
                                >
                                    <FontAwesomeIcon icon={faShoppingCart}/> Die {props.orderProgress ? minContentAmount - props.orderProgress!.orderedAmount : "paar"} {contentUnit} würde ich im Zweifel auch noch nehmen
                                </Button>
                            </div>
                            <div className={"mt-1"}>
                                <Button color={"warning"} onClick={() => setOpen(!open)} style={{width: "16rem"}}>
                                    <FontAwesomeIcon icon={faChevronUp}/> Okay, schauen wir mal
                                </Button>
                            </div>
                        </>
                      }
                      {
                        (progress >= 1 || !allowSplitting) &&
                        <div className={"mt-1"}>
                            <Button color={"success"} onClick={() => setOpen(!open)} style={{width: "16rem"}}>
                                <FontAwesomeIcon icon={faChevronUp}/> Okay, cool
                            </Button>
                        </div>
                      }
                      {
                        !props.order.proposedAmount &&
                        <div className={"mt-1"}>
                          <Button
                            color={"danger"}
                            onClick={() => postOrder({minAmount: 0, preferredAmount: 0, maxAmount: 0})}
                            style={{width: "16rem"}}
                          >
                            <FontAwesomeIcon icon={faArrowLeft}/> Hab's mir anders überlegt
                          </Button>
                        </div>
                      }
                    </div>
                </>
              }
            </div>
          </Col>
        </Row>
      </CardBody>
    </Collapse>
  </Card>;
}